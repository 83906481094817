import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.interval = this.element.getAttribute("data-remote-refresh-interval")
    this.url = this.element.getAttribute("data-remote-url")
    if (this.interval) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    fetch(this.url)
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
      })
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.interval)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}