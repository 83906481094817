import { Controller } from "stimulus";

export default class extends Controller {
  submit(event) {
    event.preventDefault();
    const formElement = event.target;

    // Show loader and backdrop
    document.getElementById('loader').style.display = 'block';
    document.getElementById('backdrop').style.display = 'block';

    fetch(formElement.action, {
      method: 'POST',
      body: new FormData(formElement),
      dataType: 'script',
      headers: {
        'Accept': 'text/html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    })
    .then(response => response.text())
    .then(html => {
      document.getElementById('output_report').innerHTML = html;
    })
    .catch(error => console.log(error))
    .finally(() => {
      // Hide loader and backdrop
      document.getElementById('loader').style.display = 'none';
      document.getElementById('backdrop').style.display = 'none';
    });
  }
}
